<template>
  <div>
    <div class="main" v-if="loaded">
      <v-expansion-panels>
        <v-expansion-panel v-for="(up, up_i) in userProfilings" :key="'up_'+up_i"
          @click.native="userProfilingData[up._id] == undefined ? fetchUserProfilingData(up) : false"
        > 
          <v-expansion-panel-header>{{ $languageProcessor.getEnText(up.name) }}</v-expansion-panel-header>
          <v-expansion-panel-content>          
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn class="btnPlacement" @click="updateAll()" color="primary">Update All</v-btn> -->
              <v-btn color="primary" @click="debug = !debug">{{ debug ? "Hide" : "Show" }} IDs</v-btn>
            </v-card-actions>

            <!-- User Profile Info -->
            <table class="table table-bordered">
              <thead class="thead-dark" v-if="up">
                <tr>
                  <th>{{ $languageProcessor.getEnText(up.name) }}</th>
                  <th>{{ $languageProcessor.getEnText(up.description) }}</th>
                </tr>
              </thead>
              <thead class="thead-light">
                <tr>
                  <th style="width:50%" scope="col">Title</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(upValue, upKey, upo_i) in up" :key="'upo_'+upo_i">
                  <td v-if="visibleUPInfo.includes(upKey)" class="bold">
                    {{ upKey }}
                  </td>
                  <td v-if="visibleUPInfo.includes(upKey)">
                    {{ upValue }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered" v-if="up && debug">
              <thead class="thead-light">
                <tr>
                  <th style="width:50%" scope="col">Title</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>userId</td>
                  <td><a :href="'/mobile/user/'+ $route.params.userId +'/profile'" target="_blank">{{ $route.params.userId }}</a></td>
                </tr>
                <tr>
                  <td>userProfilingId</td>
                  <td>{{ $route.params.userProfilingId }}</td>
                </tr>
                <tr v-if="$route.query.userProfilingDataId">
                  <td>userProfilingDataId</td>
                  <td>{{ $route.query.userProfilingDataId }}</td>
                </tr>
              </tbody>
            </table>

            <!-- User Profile Data -->
            <div v-if="!userProfilingDataReady">
              <center>Fetching data...</center>
            </div>
            <div v-else-if="userProfilingDataReady && userProfilingData[up._id] && userProfilingData[up._id].length > 0">
              <table v-for="(userProfilingDataArray, upd_i) in userProfilingData[up._id]" class="table table-bordered" :key="'up_'+up_i+'upd_'+upd_i">
                <thead class="thead-dark">
                  <tr>
                    <th colspan="1">Submitted At</th>
                    <th colspan="1">{{ $moment(userProfilingDataArray.submissionAt).format() }}</th>
                    <th colspan="1">
                      <v-btn v-if="userProfilingData[up._id][upd_i].valid" color="error" class="ml-2" @click="rejectSubmission(userProfilingData[up._id][upd_i])">Reject</v-btn>
                      <v-btn color="error" class="ml-2" @click="deactivateUser(userProfilingData[up._id][upd_i])">Deactivate</v-btn>
                      <v-btn color="primary" class="ml-2" @click="updateUserProfilingData(userProfilingData[up._id][upd_i])">Update</v-btn>
                    </th>
                  </tr>
                </thead>
                <thead class="thead-light">
                  <tr>
                    <th style="width:30%" scope="col">Title</th>
                    <th style="width:50%" scope="col">Answer</th>
                    <th style="width:20%" scope="col">Question Type</th>
                  </tr>
                </thead>
                <tbody v-for="(data, updd_i) in userProfilingDataArray.data" :key="'up_'+up_i+'upd_'+upd_i+'updd_i'+updd_i">
                  <tr v-if="data != undefined && data != null">
                    <td>{{ data.title }}</td>
                    <td>
                      <v-textarea v-if="!['camera', 'cameraRecognition', 'imageUrlQuestion', 'location'].includes(data.questionType)"
                        :value="userProfilingData[up._id][upd_i].data[updd_i]['answer']"
                        @change.native="userProfilingData[up._id][upd_i].data[updd_i]['answer'] = $event.target.value"
                        solo
                        auto-grow
                        hide-details
                        rows="1"
                      />
                      <v-img v-else-if="(data.questionType == 'camera' || data.questionType == 'cameraRecognition') && data.answer"
                        class="white--text"
                        height="100%"
                        width="500px"
                        :src="`data:image/jpeg;base64,${data.answer}`"
                      />
                      <v-img v-else-if="data.questionType == 'imageUrlQuestion' && data.answer" class="white--text" height="100%" width="500px" :src="data.answer" />
                      <Map v-else-if="data.questionType == 'location' && data.answer" :coordinates="data.answer" :zoom="12" />
                      <div v-else >{{ data.answer }}</div>
                    </td>
                    <td>{{ data.questionType }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <center>No Data is available at the moment.</center>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();

export default {
  components: {
    Map
  },
  data() {
    return {
      visibleUPInfo: ['status', 'activityType', 'description'],
      debug: this.$route.query.debug == "true" ? true : false,
      loaded: false,
      userProfilings: [],
      userProfilingData: {},
      userProfilingDataReady: true,
    };
  },
  mounted() {
    if (this.$route.params && this.$route.params.userId && this.$route.params.userProfilingId) {
      let dataToSend = { 
        userId: this.$route.params.userId, 
        userProfilingId: this.$route.params.userProfilingId 
      }

      this.fetchUserProfiling(dataToSend)
    } else {
      this.redirectToNotFoundPage();
    }
  },
  methods: {
    fetchUserProfiling(params){
      this.$setLoader();

      service.getUserProfiling(params).then(r => {
        if (this.debug) {
          // eslint-disable-next-line
          console.log(r.data)
        }

        this.userProfilings = [r.data]
        this.loaded = true
        this.$disableLoader();
      })
    },

    fetchUserProfilingData(up) {
      let dataToSend = { 
        userProfilingId: up._id, 
        userId: this.$route.params.userId 
      }

      if (this.$route.query.userProfilingDataId) {
        dataToSend.userProfilingDataId = this.$route.query.userProfilingDataId
      }

      this.userProfilingDataReady = false
      
      service.getUserProfilingData(dataToSend).then(r => {      
        this.userProfilingData[up._id] = r.data;
                
        this.$nextTick (() => {
          this.userProfilingDataReady = true;
        });
      })
    },

    deactivateUser(data) {
      this.$setLoader();

      let dataToSend = {
        userId: data.userId,
        reason: `Rejected UP (${data.userProfilingId}) submission (${data._id}) at (${data.submissionAt})` ,
        doneBy: this.$store.state.userProfile.name,
        createdAt: new Date()
      }

      service.deactivateUser(dataToSend).then(() => {
        this.$disableLoader();
      })
    },

    rejectSubmission(data) {
      this.$setLoader();

      service.changeUPDataStatus({ 
        userProfilingId: data.userProfilingId,
        userProfilingDataId: data._id,
        userId: data.userId,
      }).then(() => {
        this.userProfilingDataReady = false
        
        this.$nextTick (() => { 
          data.valid = false
          this.userProfilingDataReady = true;
        });

        this.$disableLoader();
      })
    },
    redirectToNotFoundPage() {
      this.$router.push({ path: "/404" });
      this.$disableLoader();
    },
    updateUserProfilingData(UPData) {
      UPData.updatedAt = new Date()

      this.$setLoader()

      service.updateUserProfilingData(UPData).then(() => {
        this.$disableLoader()
      })
    },
  }
};
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/bootstrap.scss";

table {
  table-layout: fixed; 
  width: 100%
}

td {
  word-wrap: break-word;
}

#activity {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}

#activity td,
#activity th {
  border: 1px solid #ddd;
  padding: 8px;
}

#activity tr:nth-child(even) {
  background-color: #f2f2f2;
}

#activity tr:hover {
  background-color: #ddd;
}

#activity th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #2ca8a0;
  color: white;
  font-size: 18px;
  max-width: 10px !important;
}

#activity td {
  font-size: 16px;
}

.btnPosition2 {
  margin-top: 118px;
  margin-left: -85px;

  flex-direction: column;
}

.main {
  display: -webkit-flex; /* Safari */
  -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
  display: flex;
  flex-direction: column;
}
</style>